$(function() {
  window.display_form_errors = function(errors, formSelector, fieldPrefix) {
    for(var field in errors) {
      if (Object.prototype.hasOwnProperty.call(errors, field)) {
        $(formSelector).find(fieldPrefix + field).addClass("has-error").append("<span class='help-block'>" + errors[field].join(", ") + "</span>");
      }
    }
    $(formSelector).bind("submit", function() {
      $(formSelector + ' .has-error .help-block').remove();
      $(formSelector + ' .has-error').removeClass("has-error");
    });
  }

  $('body').on('click', '[data-hide-and-reset]', function(e) {
    e.preventDefault();
    var target = $($(this).data('hide-and-reset'));
    target.hide('fast').removeClass('active').find('input, select, textarea').each(function(elem) {
      $(elem).val('');
    });
  });
  $('body').on('click', '[data-show]', function(e) {
    e.preventDefault();
    var target = $($(this).data('show'));
    target.show('fast');
  });
  $('body').on('click', '[data-hide]', function(e) {
    e.preventDefault();
    var target = $($(this).data('hide'));
    target.hide('fast');
  });

  $('body').on('click', '[data-fill-target]', function(e) {
    $(this).blur();
    var target = $($(this).data('fill-target'));
    var value = $(this).data('value');
    var multiple = $(this).data('multiple');
    var submit = $(this).data('submit');
    if(multiple) {
      if($(this).data('selected')) {
        $(this).removeAttr('data-selected').removeClass("btn-dark").addClass("btn_gray");
      } else {
        $(this).attr('data-selected', true).addClass("btn-dark").removeClass("btn_gray");
      }
      target.val(
        $('[data-fill-target="' + $(this).data('fill-target') + '"][data-selected=true]').toArray().map(function(e) { return $(e).data('value'); }).join(",")
      );
    } else {
      $('[data-fill-target="' + $(this).data('fill-target') + '"][data-selected=true]').removeAttr('data-selected').removeClass('btn-dark').addClass("btn_gray");
      $(this).attr('data-selected', true).addClass('btn-dark').removeClass("btn_gray");
      target.val(value);
    }
    if(!submit) {
      e.preventDefault();
      return false;
    }
  });


  window.refresh_custom_elements = function() {
    $('[data-fill-target]').each(function() {
      $(this).removeClass("btn-default");
      var target = $($(this).data('fill-target'));
      var value = $(this).data('value');
      var multiple = $(this).data('multiple');
      var selected = $(target).val();
      var submit = $(this).data('submit');
      //console.log($(this).data('fill-target') + " : Selected :" + selected + " Value "+value + " Selected" + (selected.toString() === value.toString()));

      if(!multiple && selected && selected.toString() === value.toString()) {
        if(submit !== true) { $(this).click(); }
      }
      if(multiple) {
        selected = selected.split(",");
        if(in_array(value, selected)) {
          $(this).attr('data-selected', true).addClass("btn_green").removeClass("btn_gray");
        }
      }
    });
  }

  refresh_custom_elements();
});
