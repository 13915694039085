document.addEventListener('DOMContentLoaded', function () {
  const legacyInfoBanner = document.querySelector('#info_banner');
  const appInfoBanner = document.querySelector('#app_info_banner')

  function initBanner(infoBanner, cookieName, expiresDays) {
    var cookieValue = getCookie(cookieName);

    if (cookieValue && cookieValue == '1') {
      infoBanner.classList.add('d-none');
    } else {
      infoBanner.classList.remove('d-none');
    }

    infoBanner.querySelector(".info-banner-close").addEventListener('click', function (event) {
      hideBanner(infoBanner);
      setCookie(cookieName, '1', expiresDays);
    });
  }

  function hideBanner(element) {
        element.style.transition = 'opacity 400ms';
        element.style.opacity = '0';
        element.addEventListener('transitionend', function () {
          element.classList.add('d-none');
        });
  }

  function setCookie(name, value, days) {
    var date = null;
    if (days) {
      date = new Date();
      date.setDate(date.getDate() + days);
    }
    document.cookie = name + "=" + encodeURIComponent(value) + "; path=/" + (date ? `; expires=${date.toUTCString()}` : '');
  }

  function getCookie(name) {
    var match = document.cookie.match(new RegExp(name + "=(.*?)(?:;|$)"));
    if (match) {
      return unescape(match[1]);
    }
  }

  // legacy info_banner, cookie used to hide for 7 days
  if (legacyInfoBanner) { initBanner(legacyInfoBanner, 'hideInfoBanner', 7) }

  // investor info_banner (desktop + mobile), shared cookie used to hide for single session
  if (appInfoBanner) { initBanner(appInfoBanner, 'hideAppInfoBanner', null) }
});
